<template>
  <v-container
    id="ruffle"
    fluid
    tag="section"
    class="d-flex flex-column justify-center align-center container-full-size"
  >
    <v-btn
      v-if="!client && !loading"
      color="success"
      class="btn-generate"
      @click="generate"
    >
      {{ str['generate_ruffle'] }}
    </v-btn>

    <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="green"
      indeterminate
      class="mt-5"
    ></v-progress-circular>

    <div v-if="client" class="winner-container mt-5">
      <v-card class="winner-card" elevation="10">
        <v-card-title class="row-flex-align-center text-center mb-10">
          <h2>🎉 {{ str['winner'].toUpperCase() }} 🎉</h2>
        </v-card-title>
        <v-card-text class="text-center">
          <p><strong>{{ str['id'] }}:</strong> {{ client.id }}</p>
          <p><strong>{{ str['email'] }}:</strong> {{ client.email }}</p>
          <p><strong>{{ str['name'] }}:</strong> {{ client.name }}</p>
          <p><strong>{{ str['mobile_phone'] }}:</strong> {{ client.mobile_phone }}</p>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    data () {
      return {
        str: window.strings,
        user: Utils.getUser(),
        client: null,
        loading: false,
      }
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
    },
    methods: {
      generate: function () {
        this.loading = true
        Api.generateRuffle((response) => {
          if (response.success && response.data && response.data.user_id) {
            Api.getUserWithId({
              id: response.data.user_id
            }, (responseUser) => {
              this.loading = false
              if (responseUser.success) {
                this.client = responseUser.data[0]
              } else {
                this.$alert(
                  responseUser.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          } else {
            this.loading = false
            this.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
    },
  }
</script>

<style scoped>
.btn-generate {
  font-size: 1.5rem;
  padding: 15px 30px;
  border-radius: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.btn-generate:hover {
  transform: scale(1.1);
}

.winner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.winner-card {
  max-width: 400px;
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.winner-card h2 {
  text-align: center;
  color: #000;
}

.winner-card p {
  text-align: left;
  color: #000;
}
</style>
